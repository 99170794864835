import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios, { $axios } from "@/utils/axios";
import i18n from "@/i18n";
import { IAbibaroStatus } from "@/interfaces/Student/IAbibaroStatus";
import createCancelableRequest from "@/utils/axiosCancelable";

@Component
export default class AbibaroRequestMixin extends Vue {
  private abibaroCancelableRequest = createCancelableRequest(AbibaroRequestMixin.name);
  private checkAbibaroCancelableRequest = createCancelableRequest(AbibaroRequestMixin.name);

  public abibaroIsLoading = false;
  public getLicenceClasses: Array<any> = [];
  public sourceAbibaroRegistrationQrCode = "";
  public imageLoading = false;
  public loginCode = "";
  public hasSource = false;

  public async getAbibabroLicenceClassesAction(options: any): Promise<any> {
    const { resource, id, data } = options;
    const url = resource + (id ? "/" + id : +"");
    const params = data ? data : null;

    this.abibaroIsLoading = true;
    return await axios
      .get(url, params)
      .then((res: any) => {
        const { data } = res;
        this.abibaroIsLoading = false;
        this.getLicenceClasses = data;
        return data;
      })
      .catch((e: any) => {
        return e;
      });
  }
  public async getAbibaroDetails(educationId: any): Promise<any> {
    this.abibaroIsLoading = true;
    return await $axios
      .get(`/abibaro/learn-details/${educationId}`)
      .then((res: any) => {
        if (res.data) {
          this.abibaroIsLoading = false;
        }
        return res;
      })
      .catch((e: any) => {
        this.abibaroIsLoading = false;
        return e;
      })
      .finally(() => {
        this.abibaroIsLoading = false;
      });
  }

  public async getAbibaroRegistrationQrCode(educationId: any): Promise<any> {
    this.imageLoading = true;
    this.sourceAbibaroRegistrationQrCode = "";
    return await $axios
      .get(`/abibaro/qr-code-and-login-info/${educationId}`)
      .then((res: any) => {
        const { loginCode, qrCode } = res.data;
        if (res.data) {
          this.imageLoading = false;
          this.loginCode = loginCode;
          const image = new Image();
          this.hasSource = !!qrCode;
          image.src = "data:image/png;charset=utf-8;base64, " + qrCode;
          this.sourceAbibaroRegistrationQrCode = image.src;
        }
        return res;
      })
      .catch((e: any) => {
        this.imageLoading = false;
        return e;
      })
      .finally(() => {
        this.imageLoading = false;
      });
  }

  public async registerStudentAction(options: any): Promise<any> {
    const { resource, id, data } = options;
    const url = resource + (id ? "/" + id : +"");
    this.abibaroIsLoading = true;

    return await axios
      .post(url, data)
      .then((res: any) => {
        const { data } = res;
        this.abibaroIsLoading = false;
        this.$toasted.success(String(i18n.t("messages.operation_successful")));
        return data || true;
      })
      .catch((e: any) => {
        this.abibaroIsLoading = false;
        this.$toasted.error(String(i18n.t("messages.operation_error")));
        return e;
      });
  }

  public studentEducationsAbibaroStatus: Array<IAbibaroStatus> = [];
  private studentEducationsAbibaroStatusQueue = 0;
  public studentEducationsAbibaroStatusError = null;
  public studentEducationsAbibaroStatusSuccess = false;
  public get studentEducationsAbibaroStatusLoading() {
    return this.studentEducationsAbibaroStatusQueue > 0;
  }

  public async getStudentEducationsAbibaroStatus(studentId: number) {
    this.studentEducationsAbibaroStatusQueue++;
    this.studentEducationsAbibaroStatusError = null;
    this.studentEducationsAbibaroStatusSuccess = false;

    return await this.abibaroCancelableRequest
      .makeRequest({ url: `abibaro/progress/${studentId}`, method: "get" })
      .then((response: AxiosResponse) => {
        this.studentEducationsAbibaroStatusSuccess = true;
        this.studentEducationsAbibaroStatus = response.data;
      })
      .catch((error: AxiosError) => {
        this.studentEducationsAbibaroStatusError = error.response?.data;
      })
      .finally(() => {
        this.studentEducationsAbibaroStatusQueue--;
      });
  }

  private checkAbibaroRegistrationLoadingQueue = 0;
  public checkAbibaroRegistrationSuccess = false;
  public checkAbibaroRegistrationError = null;
  public registeredInAbibaro = false;
  public get checkAbibaroRegistrationLoading() {
    return this.checkAbibaroRegistrationLoadingQueue > 0;
  }

  public async checkAbibaroRegistration() {
    this.checkAbibaroRegistrationLoadingQueue++;
    this.checkAbibaroRegistrationSuccess = false;
    this.checkAbibaroRegistrationError = null;

    return this.checkAbibaroCancelableRequest
      .makeRequest({ url: "financial-config/school-registered-in-abibaro", method: "get" })
      .then((res: AxiosResponse) => {
        this.checkAbibaroRegistrationSuccess = true;
        this.registeredInAbibaro = res.data;
      })
      .catch((er: AxiosError) => {
        this.checkAbibaroRegistrationError = er.response?.data;
      })
      .finally(() => {
        this.checkAbibaroRegistrationLoadingQueue--;
      });
  }

  public destroyed() {
    this.abibaroCancelableRequest.cancelPreviousRequest();
    this.checkAbibaroCancelableRequest.cancelPreviousRequest();
  }
}
