















































































































import { Component, Vue, Watch } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";

import StudentRequestMixin from "@/mixins/Request/StudentRequestMixin";
import AbibaroRequestMixin from "@/mixins/Request/AbibaroRequestMixin";

const StudentModule = namespace("student");

@Component({
  components: { FscPageHeader, SaveButton },
})
export default class AbibaroStudentRegistration extends mixins(StudentRequestMixin, AbibaroRequestMixin) {
  public name = "AbibaroStudentRegistration";

  private get educations(): Array<any> {
    return this.getLicenceClasses;
  }

  public activeClassStatus = 0;
  public tabClass = "";
  public loading = false;
  public student: any = null;

  public concept: any = null;
  public region: any = null;
  public currentEducationId = 0;
  public dataLoaded = false;
  public label = this.$t("user.fee");

  public get btnSaveDisabled() {
    if (!this.concept || !this.region || this.hasSource || this.abibaroIsLoading) {
      return true;
    }
    return false;
  }

  public get studentName() {
    if (this.student) {
      return this.student.firstName + " " + this.student.lastName;
    }
    return "<Vorname> <Nachname>";
  }

  public getStudent() {
    return (this.student as any) || null;
  }

  public activeClassStatusTabShow(index: any, activeTab: any) {
    return index === activeTab;
  }

  public async changeActiveClassStatus(index: number, education: any) {
    if (education) {
      this.tabClass = `fsc-student-info`;
    } else {
      this.tabClass = `fsc-student-opc`;
    }
    this.activeClassStatus = index;
    this.currentEducationId = this.educations[index].studentEducationId;
    this.hasSource = false;
    await this.getAbibaroRegistrationQrCode(this.currentEducationId);
    const { status, data } = await this.getAbibaroDetails(this.currentEducationId);

    if (!this.hasSource || status !== 200) {
      this.region = this.educations[index] && this.educations[index].regionList?.length === 1 ? this.educations[index].regionList[0] : null;
      this.concept = this.educations[index].conceptList.find((concept: any) => concept.default) || null;
    } else {
      this.concept = this.educations[index].conceptList.find((concept: any) => concept.conceptId === data.conceptId);
      this.region = this.educations[index].regionList.find((region: any) => region.regionId === data.regionId);
    }
  }

  public async onRegisterStudent() {
    if (!this.getStudent) return;
    const data = {
      conceptId: this.concept.conceptId,
      regionId: this.region.regionId,
      studentEducationId: this.educations[this.activeClassStatus].studentEducationId,
      salutations: this.student?.title,
      firstName: this.student?.firstName,
      lastName: this.student?.lastName,
      mobile: this.student?.mobilePhone,
      dob: this.student?.birthDate,
    };
    await this.registerStudentAction({
      resource: "abibaro/register-student",
      id: +this.student?.id, //fsc educationID
      data: data,
    });
    await this.getAbibaroRegistrationQrCode(this.currentEducationId);
  }

  public async fetchData() {
    this.hasSource = false;
    await this.fetchStudent(Number(this.$route.params.id));
    await this.getAbibabroLicenceClassesAction({
      resource: "abibaro/get-region-and-concept",
      id: this.student.id,
    });
    if (this.educations && this.educations.length > 0 && this.educations[0]?.conceptList) {
      this.concept = this.educations[0].conceptList.find((concept: any) => concept.default);
      this.region = this.educations[0].regionList?.length === 1 ? this.educations[0].regionList[0] : null;
    }

    if (this.educations && this.educations.length > 0) {
      this.currentEducationId = this.educations[0].studentEducationId;
      await this.getAbibaroRegistrationQrCode(this.currentEducationId);
      const { status, data } = await this.getAbibaroDetails(this.currentEducationId);
      if (this.hasSource && status === 200) {
        this.concept = this.educations[0].conceptList.find((concept: any) => concept.conceptId === data.conceptId);
        this.region = this.educations[0].regionList.find((region: any) => region.regionId === data.regionId);
      }
    }
  }

  public mounted() {
    this.fetchData();
    setTimeout(() => {
      this.dataLoaded = true;
    }, 600);
  }
}
